<template>
    <div>


        <v-row justify="center" v-if="!edit">
            <v-col cols="12" >
                <v-row justify="center">
                    <v-col>
                        <span class="font-weight-bold black--text pl-1">DETALLES</span>
                    </v-col>
                </v-row>
                <v-row justify="center" class="py-0 my-0">
                    <v-col class="py-0 my-0">
                        <div class="divider-secondary py-0 my-0"></div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col>
                        <v-row class="px-3">
                            <span class="font-weight-bold black--text pl-1">ORDEN DE COMPRA:</span>
                            <v-spacer></v-spacer>
                            <span class="black--text">{{ this.info.folio }}</span>
                        </v-row>
                        <v-row class="px-3">
                            <span class="font-weight-bold black--text pl-1">Proveedor:</span>
                            <v-spacer></v-spacer>
                            <span class="black--text">{{ this.info.supplier }}</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row justify="center" class="">
                    <v-col class="pb-0">
                        <div class="divider-secondary py-0 my-0"></div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col>
                        <span class="font-weight-bold black--text pl-1">PAGOS</span>
                    </v-col>
                </v-row>
                <v-row justify="center" class="">
                    <v-col class="pt-0 mt-0">
                        <div class="divider-secondary py-0 my-0"></div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col>
                        <v-row class="px-3">
                            <span class="font-weight-bold black--text pl-1">MONTO TOTAL:</span>
                            <v-spacer></v-spacer>
                            <span class="black--text">{{ this.info.total }}</span>
                        </v-row>
                        <v-row class="px-3">
                            <span class="font-weight-bold black--text pl-1">PAGOS:</span>
                            <v-spacer></v-spacer>
                            <span class="black--text">{{ this.info.paymentsCount }}</span>
                        </v-row>
                        <v-row class="px-3">
                            <span class="font-weight-bold black--text pl-1">MONTO PAGADO:</span>
                            <v-spacer></v-spacer>
                            <span class="black--text">{{ this.info.paid }}</span>
                        </v-row>
                        <v-row class="px-3">
                            <span class="font-weight-bold black--text pl-1">SALDO:</span>
                            <v-spacer></v-spacer>
                            <span class="black--text">{{ this.info.balance }}</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row justify="center" class="">
                    <v-col class="">
                        <div class="divider-secondary py-0 my-0"></div>
                    </v-col>
                </v-row>
                
            </v-col>
        </v-row>



       
        <v-row>
            <v-col>
                <datePickerForm
                    label="Fecha de pago"
                    :valueInput="values.date"
                    :rule="rules.required"
                    @valueChange="(v) => {values.date = v; delete serverErrors['date']}"
                    :errorMessages="serverErrors['date']"
                    data-cy="date-input"
                ></datePickerForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm
                    @valueChange="(v) => {values.amount = v}" 
                    :valueInput="values.amount" 
                    :rule="rules.cost" 
                    label="Monto"
                    :errorMessages="serverErrors.amount"
                    :prefix="(values.amount == '' || values.amount == null ) ? '' : '$'"
                    data-cy="amount-input"
                ></textFieldForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-2">
                <autocompleteOneForm
                    label="Cuenta emisora"
                    :loading="loadingBankAccounts"
                    :valueInput="values.bank_account_id"
                    :rule="rules.required"
                    :items="serverItems.bankAccounts"
                    :errorMessages="serverErrors.bank_account_id"
                    @valueChange="(v) => {values.bank_account_id = v; delete serverErrors.bank_account_id; delete serverErrors.bank_account; delete serverErrors.bank_account_id}"
                    data-cy="bank_account_id-input"
                ></autocompleteOneForm>
            </v-col>
        </v-row>

        

        <v-row>
            <v-col class="py-2" >
                <fileInputForm
                    label="Factura"
                    :multiple="false"
                    :valueInput="values.invoice_file"
                    :url="values.invoice_url"
                    :rule="[]"
                    @valueChange="(v) => {values.invoice_file = v; delete serverErrors.invoice_file}"
                    :errorMessages="serverErrors.invoice_file"
                    v-on:update:url="values.invoice_url = $event"
                    :disableDelete="false"
                    data-cy="invoice_file-input"
                ></fileInputForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.invoice_number = v; delete serverErrors.invoice_number; delete serverErrors.slug}" 
                    :valueInput="values.invoice_number" 
                    :rule="[]" 
                    label="Folio de la factura"
                    :errorMessages="serverErrors.invoice_number"
                    data-cy="invoice_number-input"
                ></textFieldForm>
            </v-col>
        </v-row>

    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit', 'info'],
   
    data(){
        return {
            loadingBankAccounts:false,
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
                cost: [
                    v => {
                        if(v!= null && !v.toString().match("^[0-9]+(\.[0-9]{1,2})?$"))
                            return 'Solo números válidos';
                        if(v === null || v.toString().trim() === '')
                            return 'El campo es requerido'
                        return true;
                    }
                ],
            },
            serverItems:{
                banks:[],
                bankAccounts:[],

            }
          
        }
    },
   
    mounted(){
        this.getBankAccounts();
    },
    methods: {
        
        getBankAccounts()
        {
            this.loadingBankAccounts = true;
            this.$api.bankAccount.getSelect()
                .then((resp) => {
                    this.serverItems.bankAccounts = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingBankAccounts = false;
                })
            ;
        },
       

    }
}
</script>

<style>
    .divider-secondary{
        background-color: var(--v-primary-base);
        height: 3px;
    }
</style>