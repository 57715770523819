export default class Object2Form {
    

    /**
   * Transform from object to FormData
   * @param obj source
   * @param rootName root's name
   * @param ignoreList ignore array list
   * @returns {FormData}
   */
    static objectToFormData(obj, rootName, ignoreList) {
        const formData = new FormData();

        function ignore(root){
            return Array.isArray(ignoreList)
                && ignoreList.some(function(x) { return x === root; });
        }

        function appendFormData(data, root) {
        
            if (!ignore(root)) {
                root = root || '';
                if (data instanceof File) {
                //console.log(root, data??'');
                    formData.append(root, data??'');
                } else if (Array.isArray(data)) {
                    for (let i = 0; i < data.length; i++) {
                        appendFormData(data[i], root + '[' + ']');
                    }
                } else if (typeof data === 'object' && data) {
                    for (const key in data) {
                        
                        if (Object.prototype.hasOwnProperty.call(data, key)) {
                            if (root === '') {
                                appendFormData(data[key], key);
                            } else {
                                appendFormData(data[key], root + '[' + key + ']');
                            }
                        }
                    }
                } else {
                    //if (data !== null && typeof data !== 'undefined') {
                        formData.append(root, data??'');
                        //console.log(root, data??'');
                    //}
                }
            }
        }

       

        appendFormData(obj, rootName);

        return formData;
    }


}
